<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>ESWL</h1>
            <p>
              ESWL (Extracorporeal Shock Wave Lithotripsy) merupakan terapi
              non-invasif, karena tidak memerlukan pembedahan atau pemasukan
              alat kedalam tubuh pasien.Sesuai dengan namanya, Extracorporeal
              berarti diluar tubuh, sedangkan Lithotripsy berarti penghancuran
              batu, secara harfiah ESWL memiliki arti penghancuran batu saluran
              kemih dengan menggunakan gelombang kejut (shock wave)yang
              ditransmisi dari luar tubuh.
            </p>
            <br />

            <p style="font-weight: bold">GENERASI TERBARU</p>
            <p>
              Mesin ESWL Generasi 2010 adalah Richard Wolf seri piezolith 3000
              (R). Alat ini termasuk yang paling canggih dengan kelebihan:
            </p>

            <p>– Daya pecah batu yang lebih kuat</p>
            <p>– Fokus dan akurasi lebih baik</p>
            <p>– Reaksi nyeri yang lebih minimal</p>
            <p>– Tindakan yang lebih cepat</p>
            <p>– Double Locator (pelacak batu)</p>
            <p>– Suara lebih halus, lebih nyaman</p>
            <br />

            <p style="font-weight: bold">BAGAIMANA ESWL BEKERJA ?</p>
            <p>
              ESWL bekerja melalui gelombang kejut yang dihantarkan melalui
              cairan tubuh ke batu. Gelombang ini akan memecah batu menjadi
              ukuran yang lebih kecil sehingga diharapkan dapat keluar sendiri
              melalui air kemih. Gelombang yang dipakai berupa gelombang
              ultrasonic, elektrohidrolik atau sinar laser. Metode ini tidak
              memerlukan tindakan operasi, hanya cukup mendekatkan lithotripter
              pada permukaan tubuh sesuai dengan lokasi batu kemudian gelombang
              dihantarkan selama seitar 30-60 menit, tergantung padaukuran
              dantingkat kekerasan batu.
            </p>
            <br />
            <p style="font-weight: bold">
              SEBERAPA BESAR BATU YANG BISA DIPECAH?
            </p>
            <p>
              Dengan alat terbaru, batu ginjal yang pernah dipecah bisa mencapai
              7 cm. Bahkan ada 1 pasien dengan batu berukuran 10cm dapat
              dipecah. Dengan batu berukuran besar, kadang memerlukan pemasangan
              stent (sejenis selang kecil) sebelum tindakan ESWL untuk
              memperlancar aliran air seni dan pengeluaran batu. Untuk itu
              konsultasi kepada dokter ahli urologi yang menguasai alat terbaru
              mutlak diperlukan.
            </p>
            <br />

            <p style="font-weight: bold">KAPAN ESWL BISA DILAKUKAN ?</p>
            <p>ESWL dapat dilakukan dalam keadaan:</p>
            <p>– Fungsi ginjal masih baik.</p>
            <p>
              – Tidak ada sumbatan distal (di bagian bawah saluran) dari batu.
            </p>
            <p>– Tidak ada kelainan pembekuan darah.</p>
            <p>– Tidak sedang hamil, karena menggunakan fasilitas rontgen.</p>
            <br />
            <p style="font-weight: bold">PASCA ESWL</p>
            <p>
              Pasien dapat langsung pulang, kecuali dianjurkan oleh dokter
              karena kondisi pasien yang memerlukan observasi ketat. Dapat
              beraktivitas normal setelah 24 jam pasca terapi.
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <img :src="require('../../assets/layanan/eswl.jpg')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    document.title = "ESWL RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>
